@@ -1,76 +0,0 @@
<template>
  <v-card class="pa-8">
    <h1 class="text-center">ข้อมูล Banner</h1>
    <v-row>
      <!-- <v-col cols="12" md="12" sm="12">
        <v-text-field
          v-model="form.name"
          label="ชื่อ Banners"
          outlined
          dense
          hide-details="auto"
        ></v-text-field>
      </v-col> -->
      <v-col cols="12" md="12" sm="12">
        <span>อัพโหลด Banner</span><br />
        <v-btn @click="changePic1()" class="mt-3"> เลือกรูป </v-btn>
      </v-col>
      <v-col cols="0" md="3" sm="0"> </v-col>
      <v-col cols="12" md="12" sm="12" class="text-center">
        <span>รูป Banner</span>
        <div v-if="showImage.length">
          <v-row>
            <v-col
              v-for="(item, index) in showImage"
              :key="index"
              cols="12"
              md="3"
              sm="12"
              class="text-center"
            >
              <v-img
                :src="item.picBanner"
                alt="Banner"
                contain
                height="200px"
              />
              <br />
              <v-btn
                @click="RemoveImage(item, index)"
                text
                style="border: 1px solid"
                >Remove</v-btn
              >
            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-col cols="0" md="3" sm="0"> </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="text-end">
        <v-btn @click="goToBack()">back</v-btn> &emsp;
        <v-btn color="primary" @click="submit()">save</v-btn>
      </v-col>
    </v-row>
    <input
      type="file"
      ref="image"
      id="picTure1"
      accept="image/*"
      @change="handleFileUpload($event)"
      style="display: none"
      hidden
      multiple
    />
  </v-card>
</template>
<script>
import { Encode } from "@/services";
import { Decode } from "@/services";
export default {
  data() {
    return {
      form: [],
      pic1: [],
      picture_1: "",
      showImage1: "",
      imageName1: "",

      files: [],

      showImage: [],
    };
  },
  created() {
    this.getOne();
  },
  async mounted() {},
  methods: {
    async getOne() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/banners`
      );
      this.showImage = response.data.data;

      console.log("this.showImage", this.showImage);
      for (let i in this.itemsData) {
        this.itemsData[i].index = parseInt(i) + 1;
      }

      this.showImage1 = this.form.picBanner;
    },
    goToBack() {
      this.$router.go(-1);
    },
    handleFileUpload(event) {
      const selectedFiles = Array.from(event.target.files);
      this.files = [...this.files, ...selectedFiles];
      if (this.files.length) {
        this.files.forEach((element) => {
          this.showPicture1(element);
        });
      }
    },
    showPicture1(item) {
      const files = item;
      // const files = e.target.files;
      const reader = new FileReader();
      reader.readAsDataURL(files);
      reader.onload = () => {
        this.pic1 = reader.result;
        this.form.push({ picBanner: this.pic1 });
        this.showImage.push({ picBanner: this.pic1 });
      };
      console.log("this.form", this.form);
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    async RemoveImage(item, index) {
      // this.pic1 = [];
      // this.picture_1 = "";
      // this.showImage1 = "";
      // this.imageName1 = "";
      this.$swal({
        text: "คุณต้องการยืนยันลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ยืนยันลบข้อมูล",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await this.axios.delete(
              `${process.env.VUE_APP_API}/banners/${item.id}`
            );
            console.log("update status", response);
            console.log(response.data.response_status);
            if (response.data.response_status == "SUCCESS") {
              this.$swal.fire({
                icon: "success",
                text: `ลบข้อมูลสำเร็จ`,
                showConfirmButton: false,
                timer: 1500,
              });
              this.$router.go(0);
            } else {
              this.$swal.fire({
                icon: "error",
                text: `ลบข้อมูลไม่สำเร็จ`,
                showConfirmButton: false,
                timer: 1500,
              });
            }
          } catch (error) {
            console.error("Error occurred while submitting data:", error);
          }
        }
      });
    },
    async submit() {
      // const data = {
      //   ...this.form,
      // };
      // console.log("data", data);
      this.$swal({
        text: "คุณต้องการยืนยันบันทึกข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ยืนยันข้อมูล",
      }).then(async (result) => {
        if (result.isConfirmed) {
          let allSuccess = true;
          try {
            for (const element of this.form) {
              const data = {
                picBanner: element.picBanner,
              };
              const response = await this.axios.post(
                `${process.env.VUE_APP_API}/banners`,
                data
                // auth
              );
              console.log(response);
              if (response.data.response_status !== "SUCCESS") {
                allSuccess = false;
              }
            }

            if (allSuccess) {
              this.$swal.fire({
                icon: "success",
                text: `แก้ไขข้อมูลสำเร็จ`,
                showConfirmButton: false,
                timer: 1500,
              });
              this.$router.go(0);
            } else {
              this.$swal.fire({
                icon: "error",
                text: `เกิดข้อผิดพลาดในการบันทึกข้อมูล`,
              });
            }
          } catch (error) {
            console.error("Error occurred while submitting data:", error);
          }
        }
      });
    },
  },
};
</script>
